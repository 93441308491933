import React from 'react'
import Article, { VARIANT_TEXT_ALIGN_CENTERED_NARROW } from '../modules/Article.js'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'

const pageConfig = {
  title: `AGB - ${config.siteTitle}`,
  slug: `agb`,
  path: `agb`
}

const Page = ({ data }) => {
  const postNode = {
    title: pageConfig.title,
  }

  const block1 = {
    as: 'header',
    leading: 5,
    variant: VARIANT_TEXT_ALIGN_CENTERED_NARROW,
    headline: `AGB`,
    lead: `<p>Unsere Allgemeine Geschäftsbedingungen stellen wir Ihnen nachstehend als PDF Dokument bereit.</p>`,
    actions: [
      {
        variant: 'secondary',
        href: '#',
        label: 'Unsere AGB (PDF)'
      }
    ]
  }

  return (
    <Layout>
      <Helmet>
        <title>{pageConfig.title}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath={pageConfig.path} customTitle />

      <div>
        <article>
          <Article {...block1}></Article>
        </article>
      </div>
    </Layout>
  )
}

export default Page
